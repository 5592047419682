<template>
  <v-container>
    <v-card :loading="loading.list">
      <v-data-table
        :search.sync="searchPhrase"
        :headers="[
          { text: 'Farm ID', value: 'farm_id' },
          { text: 'Farmer', value: '_farmer_id.full_name' },
          { text: 'Name', value: 'name' },
          { text: 'Location', value: 'location' },
          { text: 'Longitude', value: 'location.coordinates[1]' },
          { text: 'Latitude', value: 'location.coordinates[0]' },
          {text: 'Size', value: 'size'},
          {text: 'Age', value: 'age'},
          {text: 'Crop Type', value: 'crop_type'},
          {text:'Ownership', value: 'farm_ownership'},
          {text:'Crop Yeild', value: 'crop_yeid'},
          {text:'Fungicide type', value: 'pest_control'},
          {text:'Cocoa Buying', value: 'cocoa_buying'},
          {text:'Fertilizer Type', value: 'fertilizer_type'},
          {text:'Insecticide type', value: 'chemical_type'},
          {text:'Road Condition (Dry)', value: 'road_conditions'},
          {text:'Road Condition (Rainy)', value: 'road_conditions_rainy'},
          // {text:'Photo Of Farm', value: 'photo_of_farm'},
          // {text:'Seedlings', value: 'seedlings'},
          {text:'Existing shade trees', value: 'existing_shade_trees'},
          // {text:'Type of shade trees', value: 'type_of_shade_trees'},
          {text:'Organic status', value: 'organic_status'},
          {text:'Organic id', value: 'organic_id'},
          {text:'Farm Accessiblity', value: 'farm_accessibility'},
          { text: 'Compliance', value: 'compliance' },
          // { text: 'Actions', value: 'actions', sortable: false },
          // {align: 'center', text: 'Farm', value: 'farm'},
          { align: 'center', text: 'Farmer', value: 'farmer' },
          {
            align: 'center',
            text: 'Farmer Community',
            value: '_farmer_id.community',
          },
          { align: 'center', text: 'Action', value: 'action' },
        ]"
        :items="farms"
      >
        <template v-slot:top>
          <v-toolbar flat rounded color="primary" dark>
            <v-toolbar-title>Farms</v-toolbar-title>
            <v-spacer />
            <v-text-field
              v-model="searchPhrase"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              hide-details
              filled
              rounded
              dense
            />
            <v-spacer />
            <v-btn icon @click="downloadFarmsCSV">
                <v-icon color="white">mdi-microsoft-excel</v-icon>
            </v-btn>
            <!-- <v-btn icon>
              <a :href="`${config.serverURL}/farm/download?token=${token}&factoryId=${(claims.factories || [])[0]}`">
                <v-icon color="white">mdi-microsoft-excel</v-icon>
              </a>
            </v-btn> -->
            <v-btn
              color="primary"
              rounded
              large
              depressed
              :icon="isMobile"
              v-if="farmer_id"
              @click="dialog = true"
            >
              <v-icon left color="white">mdi-plus</v-icon>
              <span v-if="!isMobile"> Create </span>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.location="{ item }">
          <v-btn @click="showMap(item)" text rounded>
            <v-icon left>mdi-map</v-icon>
            {{ item.location.address }}
          </v-btn>
        </template>
        <template v-slot:item._farmer_id.full_name="{ item }">
          {{ item._farmer_id.full_name }}
        </template>
        <template v-slot:item.photo_of_farm="{ item }">
            <v-img :src="item.photo_of_farm" height="50" width="50" 
                style="background-size: contain !important"/>
        </template>
        <template v-slot:item.farm="{ item }">
          <v-btn text color="info" rounded>
            <v-icon left>mdi-account-circle</v-icon>
            View
          </v-btn>
        </template>
        <template v-slot:item.farmer="{ item }">
          <v-btn
            :to="{ name: 'farmer', params: { farmer_id: item._farmer_id._id } }"
            rounded
            text
            color="primary"
            block
          >
            <v-icon left>mdi-account-circle</v-icon>
            Farmer
          </v-btn>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn @click="showEditable(item)" icon color="info">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="showDeletable(item)" icon color="error">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="show_edit" max-width="520">
      <v-card v-if="editableFarm._id">
        <v-card-title class="mb-4">
          Edit Farm
          <v-spacer />
          <v-icon @click="show_edit = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editableFarm.name"
            rounded
            filled
            label="Name"
          ></v-text-field>
          <v-text-field
            @click="showEditableMap"
            readonly
            :value="editableFarm.location.address"
            rounded
            filled
            label="Location"
          />
          <!-- <v-text-field
            readonly
            type="text"
            v-model="editableFarm._farmer_id.community"
            rounded
            filled
            label="Farm Community"
          ></v-text-field> -->
          <!-- <v-select2
            v-model="editableFarm._farmer_id.community"
            :value="editableFarm._farmer_id.community._id"
            :options="options.communities"
            :reduce="(community) => community._id"
            label="name"
            placeholder="Select Community Location"
          ></v-select2> -->
          <v-text-field
              type="text"
              v-model="editableFarm.location.coordinates[1]"
              rounded
              filled
              label="Longitude"
          >
          </v-text-field>
          <v-text-field
              type="text"
              v-model="editableFarm.location.coordinates[0]"
              rounded
              filled
              label="Latitude"
          >
          </v-text-field>
          <v-text-field
              type="number"
              v-model="editableFarm.size"
              rounded
              filled
              label="Size"
          >
          </v-text-field>

          <v-text-field
              type="number"
              v-model="editableFarm.age"
              rounded
              filled
              label="Age"
          >
          </v-text-field>

          <v-text-field
              v-model="editableFarm.crop_type"
              rounded
              filled
              label="Crop Type"
          >
          </v-text-field>

          <v-text-field
              v-model="editableFarm.farm_ownership"
              rounded
              filled
              label="Farm Ownership"
          >
          </v-text-field>

          <v-text-field
              v-model="editableFarm.crop_yeid"
              rounded
              filled
              label="Crop Yield"
          >
          </v-text-field>

          <v-text-field
              v-model="editableFarm.pest_control"
              rounded
              filled
              label="Fungicide type"
          >
          </v-text-field>

          <!-- <v-text-field
              v-model="editableFarm.cocoa_buying"
              rounded
              filled
              label="Cocoa Buying"
          >
          </v-text-field> -->

          <v-text-field
              v-model="editableFarm.fertilizer_type"
              rounded
              filled
              label="Fertilizer Type"
          >
          </v-text-field>

          <v-text-field
              v-model="editableFarm.chemical_type"
              rounded
              filled
              label="Insecticide type"
          >
          </v-text-field>

          <v-text-field
              v-model="editableFarm.road_conditions"
              rounded
              filled
              label="Road Condition (Dry)"
          >
          </v-text-field>
          <v-text-field
              v-model="editableFarm.road_conditions_rainy"
              rounded
              filled
              label="Road Condition (Rainy)"
          >
          </v-text-field>
          <v-text-field
              v-model="editableFarm.farm_accessibility"
              rounded
              filled
              label="Farm Accessibility"
          >
          </v-text-field>
          
          <!-- <v-text-field
              v-model="editableFarm.seedlings"
              rounded
              filled
              label="Seedlings"
          >
          </v-text-field> -->
          <v-text-field
              v-model="editableFarm.existing_shade_trees"
              rounded
              filled
              label="Existing shade trees"
          >
          </v-text-field>
          <!-- <v-text-field
              v-model="editableFarm.type_of_shade_trees"
              rounded
              filled
              label="Type of shade trees"
          >
          </v-text-field> -->
          <v-select
            v-model="editableFarm.organic_status"
            label="Organic status"
            filled
            rounded
            :items="[
              { text: 'Organic', value: 'Organic' },
              { text: 'Not Organic', value: 'Not Organic' },
            ]"
          />
          <v-text-field
              v-model="editableFarm.organic_id"
              rounded
              filled
              label="Organic Id"
          >
          </v-text-field>
         
          <v-select
            v-model="editableFarm.compliance"
            :items="options.compliance_options"
            item-text="text"
            item-value="value"
            rounded
            label="Compliance"
            filled
          />
          <!-- <p>Farm Picture</p>
            <v-card
              @click="takePicture"
              height="200"
              class="mb-5"
              rounded="1"
            >
              <div class="text-center mb-5" v-if="!editableFarm.photo_of_farm">
                <v-icon size="120" color="primary" class="mt-7 mb-5"
                  >mdi-camera</v-icon
                >
                <p>Take Farm Picture</p>
              </div>
              <v-img
                aspect-ratio="1"
                v-else
                height="200"
                style="background-size: contain !important"
                alt="profile pic"
                :src="editableFarm.photo_of_farm"
              />
            </v-card> -->
          <v-btn
            @click="updateFarm"
            :loading="loading.updating"
            block
            color="primary"
            rounded
            large
          >
            Update
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="show_delete_alert"
      max-width="520"
      content-class="rounded-xl"
    >
      <v-card color="warning" dark rounded="xl">
        <v-card-title>
          Confirm Deletion
          <v-spacer></v-spacer>
          <v-icon @click="show_delete_alert = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          Deleting this farm is irreversible. Do you wish to proceed?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="show_delete_alert = false" text rounded large
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn @click="deleteFarm" color="error" depressed rounded large
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="map.show">
      <app-map
        :default-location="map.location"
        @location="map.callback"
        @close="closeMap"
      />
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Farm</span>
        </v-card-title>
        <v-card-text>
          <v-text-field

            v-model="newFarm.name"
            rounded
            filled
            label="Name"
          ></v-text-field>
          <v-text-field
              type="text"
              v-model="newFarm.longitude"
              rounded
              filled
              label="Longitude"
          >
          </v-text-field>
          <v-text-field
              type="text"
              v-model="editableFarm.latitude"
              rounded
              filled
              label="Latitude"
          >
          </v-text-field>
          <v-text-field
              type="number"
              v-model="newFarm.size"
              rounded
              filled
              label="Size"
          >
          </v-text-field>

          <v-text-field
              type="number"
              v-model="newFarm.age"
              rounded
              filled
              label="Age"
          >
          </v-text-field>

          <v-text-field
              v-model="newFarm.crop_type"
              rounded
              filled
              label="Crop Type"
          >
          </v-text-field>

          <v-text-field
              v-model="newFarm.farm_ownership"
              rounded
              filled
              label="Farm Ownership"
          >
          </v-text-field>

          <v-text-field
              v-model="newFarm.crop_yeid"
              rounded
              filled
              label="Crop Yield"
          >
          </v-text-field>

          <v-text-field
              v-model="newFarm.pest_control"
              rounded
              filled
              label="Fungicide type"
          >
          </v-text-field>

          <!-- <v-text-field
              v-model="newFarm.cocoa_buying"
              rounded
              filled
              label="Cocoa Buying"
          >
          </v-text-field> -->

          <v-text-field
              v-model="newFarm.fertilizer_type"
              rounded
              filled
              label="Fertilizer Type"
          >
          </v-text-field>

          <v-text-field
              v-model="newFarm.chemical_type"
              rounded
              filled
              label="Insecticide type"
          >
          </v-text-field>

          <v-text-field
              v-model="newFarm.road_conditions"
              rounded
              filled
              label="Road Condition (Dry)"
          >
          </v-text-field>
          <v-text-field
              v-model="newFarm.road_conditions_rainy"
              rounded
              filled
              label="Road Condition (Rainy)"
          >
          </v-text-field>

          <!-- <v-text-field
              v-model="newFarm.photo_of_farm"
              rounded
              filled
              label="Photo of Farm"
          >
          </v-text-field> -->
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="newFarm.farm_accessibility"
            rounded
            filled
            label="Farm Accessibility"
          >
          </v-text-field>
        </v-card-text>

        <!-- <v-card-text>
          <v-text-field
            v-model="newFarm.seedlings"
            rounded
            filled
            label="Seedlings"
          >
          </v-text-field>
        </v-card-text> -->

        <v-card-text>
          <v-text-field
            v-model="newFarm.existing_shade_trees"
            rounded
            filled
            label="Existing Shade Tree"
          >
          </v-text-field>
        </v-card-text>

        <v-card-text>
        <v-select
            v-model="newFarm.organic_status"
            label="Organic status"
            filled
            rounded
            :items="[
              { text: 'Organic', value: 'Organic' },
              { text: 'Not Organic', value: 'Not Organic' },
            ]"
          />
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="newFarm.organic_id"
            rounded
            filled
            label="Organic Id"
          >
          </v-text-field>
        </v-card-text>

       
        <v-card-text>
          <v-select
              v-model="newFarm.compliance"
              :items="options.compliance_options"
              item-text="text"
              item-value="value"
              rounded
              label="Compliance"
              filled
            />
        </v-card-text>

        <!-- <v-btn
              @click="showMap"
              text
              color="primary"
              rounded
              block
            >
              <v-icon left>mdi-map</v-icon>
              Location
            </v-btn> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createFarm"
            :disabled="newFarm.loading"
            :loading="newFarm.loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AppMap from "@/components/MapWithPlaces";
import config from "@/config";
import firebase from "firebase";
import { Plugins, CameraResultType } from "@capacitor/core";
import { v4 as uuid } from "uuid";
import { downloadxls } from "../../plugins/downloadxlsx";


const { Camera } = Plugins;


export default {
  name: "Farms",
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    farmer_id() {
      return this.$route.params.farmer_id;
    },
  },
  components: {
    AppMap,
  },
  data: () => ({
    dialog: false,
    config,
    options: {
      regions:[],
      communities:[]
    },
    searchPhrase: "",
    farms: [],
    show_edit: false,
    show_delete_alert: false,
    loading: {
      list: false,
      updating: false,
    },
    newFarm: {},
    editableFarm: {},
    token: null,
    claims: {},
    map: {
      show: false,
      location: {},
      editable: true,
      callback: () => {},
    },
    deletable_farm_id: null,
    upload_image: {
      ref: uuid(),
      is_uploaded: false,
      is_form_submitted: false,
    },
    options: {
      compliance_options: [
        { value: "Admitted", text: "Admitted" },
        { value: "Restricted", text: "Restricted" },
        { value: "Not Applicable", text: "Not Applicable" },
      ],
    }
  }),
  methods: {
    fetchFarms() {
      let request;
      if (this.farmer_id)
        request = this.$axios.get(`/farm/farmer/${this.farmer_id}`);
      else request = this.$axios.get("/farm");

      this.loading.list = true;
      request
        .then((res) => {
          this.farms = res.data;
          // console.log(this.farms);
        })
        .catch(this.showError)
        .finally(() => {
          this.loading.list = false;
        });
    },
    showEditable(farm) {
      this.editableFarm = { ...farm };
      this.show_edit = true;
    },
    closeEditable() {
      this.editableFarm = {};
      this.show_edit = false;
    },
    createFarm() {
      this.newFarm.loading = true;
      this.axios
        .post(`farm`, {
          name: this.newFarm.name,
          location: {coordinates: [this.newFarm.longitude, this.newFarm.latitude]},
          size: this.newFarm.size,
          age: this.newFarm.age,
          crop_type: this.newFarm.crop_type,
          farm_ownership: this.newFarm.farm_ownership,
          crop_yeid: this.newFarm.crop_yeid,
          pest_control: this.newFarm.pest_control,
          // cocoa_buying: this.newFarm.cocoa_buying,
          fertilizer_type: this.newFarm.fertilizer_type,
          chemical_type: this.newFarm.chemical_type,
          road_conditions: this.newFarm.road_conditions,
          road_conditions_rainy: this.newFarm.road_conditions_rainy,
          photo_of_farm: this.newFarm.photo_of_farm,
          seedlings: this.newFarm.seedlings,
          existing_shade_trees: this.newFarm.existing_shade_trees,
          organic_status: this.newFarm.organic_status,
          organic_id: this.newFarm.organic_id,
          farm_accessibility: this.newFarm.farm_accessibility,
          compliance: this.newFarm.compliance,
          farmer_id: this.farmer_id,
        })
        .then(({ data }) => {
          // console.log(data);
          this.dialog = false;
          this.newFarm = {};
          this.farms.push({
            ...data.farm,
            _farmer_id: {
              full_name: data.farmer.full_name,
              _id: data.farmer._id,
            },
          });
        })
        .catch(this.showError)
        .finally(() => (this.newFarm.loading = false));
    },
    updateFarm() {
      this.loading.updating = false;
      const { id, farm } = this.farmUpdateFormat(this.editableFarm);
      this.axios
        .put(`farm/${id}`, farm)
        .then(() => {
          this.closeEditable();
          this.fetchFarms();
        })
        .catch(this.showError)
        .finally(() => {
          this.loading.updating = false;
        });
    },
    showDeletable(farm) {
      this.deletable_farm_id = farm._id;
      this.show_delete_alert = true;
    },
    closeDeletable() {
      this.deletable_farm_id = null;
      this.show_delete_alert = false;
    },
    deleteFarm() {
      this.$axios.delete(`farm/${this.deletable_farm_id}`).then(() => {
        this.closeDeletable();
        this.fetchFarms();
      });
    },
    farmUpdateFormat(new_farm) {
      const farm = { ...new_farm };
      const id = farm._id;
      delete farm._id;
      delete farm._farmer_id;
      delete farm.__v;
      return { id, farm };
    },
    showError(err) {
      this.$root.$emit(
        "error",
        err.response ? err.response.data.message : err.message
      );
    },
    closeMap() {
      this.map.show = false;
    },
    showEditableMap() {
      this.map = {
        show: true,
        editable: true,
        location: this.editableFarm.location,
        callback: (location) => {
          this.editableFarm.location = location;
          this.map.show = false;
        },
      };
    },
    showMap(farm) {
      this.map = {
        show: true,
        editable: false,
        location: farm.location,
        callback: () => {},
      };
    },
    async takePicture() {
      const { dataUrl } = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
      });
      const img_data = await fetch(dataUrl);
      this.saveImage(await img_data.blob());
    },
    saveImage(image) {
      this.$firebase
        .storage()
        .ref(`farms/${this.upload_image.ref}`)
        .put(image)
        .then((result) => {
          return result.ref.getDownloadURL();
        })
        .then((url) => {
          this.editableFarm.photo_of_farm = url;
        });
    },
    downloadFarmsCSV() {
      this.exportFars = this.farms.map((farm) => ({
        "_id": farm._id,
        "Farm ID": farm.farm_id,
        "Farmer Name": farm._farmer_id?.full_name,
        "Name": farm.name,
        "Farmer Community": farm._farmer_id?.community,
        "Location": farm.location?.address,
        "Longitude": farm.location?.coordinates[1],
        "Latitude": farm.location?.coordinates[0],
        "Size": farm.size,
        "Age": farm.age,
        "Crop type": farm.crop_type,
        "Farm ownership": farm.farm_ownership,
        "Crop yield": farm.crop_yeid,
        "Fungicide type": farm.pest_control,
        // "Cocoa buying": farm.cocoa_buying,
        "Fertilizer type": farm.fertilizer_type,
        "Insecticide type": farm.chemical_type,
        "Road condition (Dry)": farm.road_conditions,
        "Road condition (Rainy)": farm.road_conditions_rainy,
        "Farm Accessibility": farm.farm_accessibility,
        "Existing shade trees": farm.existing_shade_trees,
        "Organic status": farm.organic_status,
        "Organic Id": farm.organic_id,
        "Compliance": farm.compliance,
      }));

      downloadxls(this.exportFars, "farms")
    },
  },
  async mounted() {

    const {token, claims} = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);
    this.token = token;
    this.claims = claims;

    Promise.all([this.$axios.get("/communities")])
      .then((res) => {
        console.log("communities",res);
        this.options.communities = res[0].data;
      })
      

  },
  created() {
    this.fetchFarms();
  },
};
</script>

<style scoped>
</style>
